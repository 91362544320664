export const isTruthy = <T>(value?: T | undefined | null | false): value is T => !!value;

export const groupBy = <K, V>(
  array: readonly V[],
  getKey: (cur: V, idx: number, src: readonly V[]) => K
): [K, V[]][] =>
  Array.from(
    array.reduce((map, cur, idx, src) => {
      const key = getKey(cur, idx, src);
      const list = map.get(key);
      if (list) list.push(cur);
      else map.set(key, [cur]);
      return map;
    }, new Map<K, V[]>())
  );

export const groupByObject = <V, V2>(
  array: readonly V[],
  getKey: (cur: V, idx: number, src: readonly V[]) => string,
  getValue: (cur: V, idx: number, src: readonly V[]) => V2
): { [x: string]: V2[] } =>
  array.reduce(
    (map, cur, idx, src) => {
      const key = getKey(cur, idx, src);
      const value = getValue(cur, idx, src);
      const list = map[key];
      if (list) list.push(value);
      else map[key] = [value];
      return map;
    },
    {} as { [x: string]: V2[] }
  );

export const findFirstItemPerGroup = <V, V2>(
  array: readonly V[],
  getKey: (cur: V, idx: number, src: readonly V[]) => string,
  getValue: (cur: V, idx: number, src: readonly V[]) => V2
): { [x: string]: V2 } => {
  const ret = {} as { [x: string]: V2 };

  array.forEach((cur, idx, src) => {
    const key = getKey(cur, idx, src);
    const value = getValue(cur, idx, src);
    if (ret[key] === undefined) {
      ret[key] = value;
    }
  });

  return ret;
};

export const uniqBy = <T>(array: T[], key: (value: T) => string | number): T[] => {
  const seen = new Set();
  return array.filter((item) => {
    const k = key(item);
    if (seen.has(k)) {
      return false;
    } else {
      seen.add(k);
      return true;
    }
  });
};

export const deepEqual = <T>(obj1: T, obj2: T, excludes?: string[]): boolean => {
  if (obj1 === obj2) return true;
  if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
    return false;
  }
  const keys1 = Object.keys(obj1) as (keyof T)[];
  const keys2 = Object.keys(obj2) as (keyof T)[];
  if (keys1.length !== keys2.length) return false;
  for (const key of keys1) {
    if (typeof key === 'string' && excludes?.includes(key)) continue;
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }
  return true;
};
